body {
    display: -webkit-box;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: 'SuisseIntl Bold';
  src: local('SuisseIntl'), url(./assets/fonts/SuisseIntl-Bold-WebM.woff) format('woff');
}

@font-face {
    font-family: 'SuisseIntl Medium';
    src: local('SuisseIntl'), url(./assets/fonts/SuisseIntl-Medium-WebM.woff) format('woff');
}

@font-face {
    font-family: 'SuisseIntl Regular';
    src: local('SuisseIntl'), url(./assets/fonts/SuisseIntl-Regular-WebM.woff) format('woff');
}


